import "./src/styles/global.css"

export const onRouteUpdate = () => {
  // ZoomInfo script (your obfuscated JavaScript code)
  window[
    (function (_ptE, _bJ) {
      var _ZXOBL = ""
      for (var _BKo0gl = 0; _BKo0gl < _ptE.length; _BKo0gl++) {
        var _SVga = _ptE[_BKo0gl].charCodeAt()
        _SVga -= _bJ
        _SVga += 61
        _SVga %= 94
        _SVga += 33
        _ZXOBL += String.fromCharCode(_SVga)
      }
      return _ZXOBL
    })(atob("cWBnKygjfHotYnwy"), 23)
  ] = "215c15a19c1698943305"

  var zi = document.createElement("script")
  zi.type = "text/javascript"
  zi.async = true
  zi.src = (function (_Nnx, _XY) {
    var _jMvM7 = ""
    for (var _M5oDzY = 0; _M5oDzY < _Nnx.length; _M5oDzY++) {
      var _LrKb = _Nnx[_M5oDzY].charCodeAt()
      _LrKb -= _XY
      _LrKb += 61
      _LrKb %= 94
      _LrKb += 33
      _jMvM7 += String.fromCharCode(_LrKb)
    }
    return _jMvM7
  })(atob("IS0tKSxRRkYjLEUzIkQseisiKS0sRXooJkYzIkQteH5FIyw="), 23)

  if (document.readyState === "complete") {
    document.body.appendChild(zi)
  } else {
    window.addEventListener("load", function () {
      document.body.appendChild(zi)
    })
  }

  // Salesloft script
  ;(function (i, s, o, g, r, a, m) {
    i["SLScoutObject"] = r
    i[r] =
      i[r] ||
      function () {
        ;(i[r].q = i[r].q || []).push(arguments)
      }
    i[r].l = 1 * new Date()
    a = s.createElement(o)
    m = s.getElementsByTagName(o)[0]
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)

    i[r]([
      "init",
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0IjoxMTEzMTR9.JMy3Pe2od5VbASBPg5Yd7NZPa3MlnvI-jwpXxG9GRRQ",
    ])
  })(
    window,
    document,
    "script",
    "https://scout-cdn.salesloft.com/sl.js",
    "slscout"
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in news
  if (
    location.search.includes("category=") ||
    location.search.includes("page=")
  ) {
    return false
  }
  const currentPosition = getSavedScrollPosition(location)
  return currentPosition || [0, 0]
}
